<template>
    <div
        class="info__card u-relative"
        :class="`info__card-${type}${hover ? ' is-hover' : ''}`"
        v-on="$listeners"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "InfoCard",
    props: {
        type: {
            type: String,
            default: "bordered",
        },
        hover: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
@use "./infocard.scss" as *;
</style>
